@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

nav {
  .heading {
    position: relative;
    background-color: #1b1d25;
    font-weight: 700;
    cursor: pointer;
    transition: .3s;

    p {
      transition: .3s;
      padding: 20px;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translate(0, -50%);
      font-size: 1.5em;
      background-color: #111;
      padding: 10px;
      border-radius: 50%;
      width: 20px;
      height: 20px;

      path {
        transition: .3s;
      }

    }

    &:hover {
      transition: .3s;
      background-color: #2d2f36;

      p {
        transition: .3s;
        color: #ff5f00;
      }

      svg path {
        transition: .3s;
        color: #ff5f00;
      }
    }
  }

  h1 {
    padding: 15px 0;
    color: #ff5f00;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2em;
    background-color: #0f1014;
    font-weight: 800;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0f1014;

    li {
      list-style: none;
      color: #fff;
      text-transform: uppercase;
      padding: 25px 30px;
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      cursor: pointer;
      transition: 0.3s;
      transform: scale(1);
      position: relative;
      z-index: 999;

      a {
        text-decoration: none;
      }

      div {
        display: none;
        cursor: default;
        z-index: 999;
      }

      &:hover {
        transition: 0.3s;
        transform: scale(1.1);
      }

      &:nth-last-child(1):hover {
        transition: .2s;
        color: #c2410c;
        transform: scale(1);
        z-index: 999;

        div.container {
          position: absolute;
          top: calc(100% - 15px);
          left: 20px;
          display: block;
          padding-top: 15px;
          background-color: transparent;
          z-index: 999;

          .list {
            padding: 10px 5px;
            background-color: #111;
            display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // grid-template-rows: repeat(12, 1fr);
            // grid-column-gap: 20px;
            grid-row-gap: 0px;
            z-index: 999;

            span {
              margin: 4px 0;
              padding: 5px 10px;
              white-space: nowrap;
              font-size: 14px;
              opacity: 0.6;
              cursor: pointer;
              transition: .2s;

              &:hover {
                transition: .2s;
                opacity: 1;
                color: #c2410c;

                a {
                  transition: .2s;
                  opacity: 1;
                  color: #c2410c;
                }
              }
            }
          }
        }
      }
    }
  }
}