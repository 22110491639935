.privacyPolicy {
    margin: 50px auto;
    max-width: 1100px;
    h1 {
        font-size: 1.7em;
        opacity: 0.7;
    }
    h2 {
        font-size: 2.2em;
        text-align: left;
        margin: 25px 0;
        margin-bottom: 8px;
        &.small {
            margin-top: 50px;
            font-size: 1.2em;
        }
    }
    p {
        opacity: 0.7;
        text-align: left;
        font-weight: bold;
        &.small {
            font-size: 0.6em;
            max-width: 80%;
        }
    }
}