.status {
  background-color: #0c0d11;
  padding: 100px 0;
  margin: 100px 0;
}
.status h1 {
  font-weight: 800;
  letter-spacing: 2px;
  font-size: 3em;
}
.status .spacer {
  width: 100%;
  height: 4px;
  background-color: #ff5f00;
  margin-top: 2px;
  margin-bottom: 15px;
}
.status .grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  max-width: 1600px;
  margin: 30px auto;
}
.status .grid .box {
  background-color: #111216;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 20px 25px;
}
.status .grid .box h2 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.4em;
  color: #eee;
}
.status .grid .box b {
  color: #48a64a;
  font-size: 0.8em;
}/*# sourceMappingURL=status.css.map */