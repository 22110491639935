.privacyPolicy {
  margin: 50px auto;
  max-width: 1100px;
}
.privacyPolicy h1 {
  font-size: 1.7em;
  opacity: 0.7;
}
.privacyPolicy h2 {
  font-size: 2.2em;
  text-align: left;
  margin: 25px 0;
  margin-bottom: 8px;
}
.privacyPolicy h2.small {
  margin-top: 50px;
  font-size: 1.2em;
}
.privacyPolicy p {
  opacity: 0.7;
  text-align: left;
  font-weight: bold;
}
.privacyPolicy p.small {
  font-size: 0.6em;
  max-width: 80%;
}/*# sourceMappingURL=privacyPolicy.css.map */