footer {
  background-color: #222;
  padding: 20px 0;
  background-image: url("../../public/img/bottomaperion.jpg");
  background-size: cover;
  background-position: center center;
}
footer div.container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin: 0 auto;
  padding: 40px 0;
}
footer div.container div.section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 33%;
  padding: 0 20px;
  margin: auto 0;
}
footer div.container div.section img {
  width: 300px;
  margin: 0 auto;
  cursor: pointer;
}
footer div.container div.section h3 {
  width: 100%;
  font-size: 1.4em;
  text-transform: uppercase;
  color: #ff5f00;
  margin-bottom: 25px;
  text-align: left;
}
footer div.container div.section .spacer {
  width: 100%;
  height: 4px;
  background-color: #ff5f00;
  margin-top: 2px;
  margin-bottom: 10px;
}
footer div.container div.section p {
  width: 100%;
  opacity: 0.7;
  font-weight: bold;
  text-align: left;
}
footer div.container div.section p svg {
  margin-right: 5px;
}
footer div.container div.section p.link {
  transition: 0.3s;
  transform: scale(1);
  width: auto;
  margin-bottom: 15px;
  cursor: pointer;
}
footer div.container div.section p.link:hover {
  transition: 0.3s;
  transform: scale(1.15);
}
footer div.container div.section .data {
  padding: 10px 10px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2em;
  font-weight: bold;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
}
footer div.container div.section .data:nth-child(2) {
  padding-top: 0;
}
footer div.container div.section .data svg {
  margin-right: 10px;
}
footer div.container div.section .data svg path {
  color: rgba(255, 255, 255, 0.6);
}
footer div.container div.section .data span {
  color: #c2410c;
}

.spacer {
  width: 100%;
  height: 3px;
  background-color: #ff5f00;
  margin-top: 2px;
  margin-bottom: 10px;
}/*# sourceMappingURL=footer.css.map */