.about {
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
}
.about .info {
  width: 50%;
  text-transform: uppercase;
  padding: 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about .info h2 {
  letter-spacing: 3px;
  font-size: 3.2em;
  margin-bottom: 10px;
  font-weight: 800;
}
.about .info p {
  font-weight: bold;
  margin-bottom: 50px;
  text-transform: none;
  max-width: 650px;
  font-size: 1.2em;
  text-shadow: 0px 0px 4px rgb(4, 4, 4);
}
.about .info a {
  padding: 12px 45px;
  border-radius: 20px;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid white;
  font-size: 1.4em;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
  transform: scale(1);
  display: inline-block;
}
.about .info a:hover {
  transition: 0.2s;
  transform: scale(1.04);
}
.about .soldier {
  width: 50%;
  position: relative;
  z-index: 3;
}
.about .soldier img {
  width: 100%;
  z-index: 3;
  position: relative;
}
.about .soldier::before {
  display: table;
  content: " ";
  position: absolute;
  z-index: 2;
  position: absolute;
  width: 700px;
  height: 700px;
  left: 28vw;
  top: 12vh;
  background: #ff5f00;
  opacity: 0.7;
  border-radius: 50%;
  filter: blur(300px);
  transform: rotate(-1.71deg);
}
.about .soldier.special::before {
  left: -20vw;
  top: -12vh;
  z-index: -1;
}/*# sourceMappingURL=about.css.map */