@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
.bgi {
  background-image: url("../../public/img/AperionLandingPage.jpg");
  height: calc(100vh - 142px);
  background-size: cover;
  background-position: center center;
  position: relative;
  div {
    position: absolute;
    top: 40%;
    left: 25%;
    transform: translate(-50%, -50%);
    width: 45%;
    h1 {
      font-weight: 700;
      font-size: 4em;
      font-family: 'Sono', sans-serif;
      letter-spacing: 3px;
    }
    p {
      text-transform: uppercase;
      color: #fff;
      font-weight: 700;
      font-size: 1.4em;
      margin: 15px 0;
      margin-bottom: 10px;
      letter-spacing: 2px;
    }
    a {
      background-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.4em;
      cursor: pointer;
      text-decoration: none;
      transition: 0.2s;
      transform: scale(1);
      display: inline-block;
      img {
        width: 300px;
      }
      &:hover {
        transition: 0.2s;
        transform: scale(1.04);
      }
    }
  }
}
.popularProducts {
  background-image: url("../../public/img/under.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 150px 0;
  h1 {
    text-transform: uppercase;
    font-size: 5em;
    letter-spacing: 4px;
  }
  h2.title {
    text-transform: uppercase;
    font-size: 1.2em;
    opacity: 0.6;
    margin: 15px 0;
  }
  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
      box-shadow: none;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }

      background-image: url("../../public/img/card.png");

      &:nth-child(2) {
        background-image: url("../../public/img/card3.png");
      }
      &:nth-child(3) {
        background-image: url("../../public/img/card2.png");
      }
      &:nth-child(4) {
        background-image: url("../../public/img/card5.png");
      }
      background-size: cover;
      background-position: center center;
      height: 450px;
      margin: 30px 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      width: 318px;
      border-radius: 12px;
      h2 {
        text-transform: uppercase;
        font-size: 1.3em;
      }
      span {
        margin: 30px 0;
        margin-bottom: 42px;
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
        b {
          font-size: 1.1em;
          display: inline;
          color: #0cf801;
          &.updating {
            color: yellow;
          }
          &.down {
            color: red;
          }
          &.comingSoon {
            color: #fff;
            opacity: 0.6;
          }
        }
      }
      button {
        font-size: 1.2em;
        margin-bottom: 13px;
        display: block;
        text-transform: uppercase;
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;
      }
    }
  }
}
