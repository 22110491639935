.videos {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 75px 0;
    h2 {
        text-transform: uppercase;
        margin-bottom: 30px;
        font-size: 2em;
    }
    div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 35px;
        grid-row-gap: 35px;
        iframe {
            border: none !important;
            outline: none !important;
            border-radius: 15px;
        }
    }
}