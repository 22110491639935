@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-shadow: 0px 0px 4px rgba(4, 4, 4, 1);
}

body {
  background-color: #191919;
  overflow-x: hidden;
  background-image: url("../../public/img/globalbg.jpg");
  //   background-size: cover;
  background-position: center center;
}

#scrollToTop {
  
  left: 30px;
  background-color: #191919;
  border: 2px solid #ff5f00;
  border-radius: 50%;
  z-index: 999;
  z-index: 3;
  position: fixed;
  bottom: 10px;
  left: 10px;
  &::before {
    background-image: url("../../public/img/arrow.png");
    display: table;
    content: " ";
    z-index: 99999;
    position: absolute;
    width: 120%;
    height: 120%;
    // background: #ff5f00;
    // opacity: 0.7;
    // border-radius: 50%;
    // filter: blur(10px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
