.products {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 150px 0;
  position: relative;
}
.products::before {
  display: table;
  content: " ";
  position: absolute;
  z-index: -1;
  position: absolute;
  width: 800px;
  height: 800px;
  left: 90vw;
  top: 100vh;
  background: #ff5f00;
  opacity: 0.7;
  border-radius: 50%;
  filter: blur(300px);
  transform: rotate(-1.71deg);
}
.products h1 {
  text-transform: uppercase;
  font-size: 38px;
  position: relative;
  margin-bottom: 20px;
  letter-spacing: 4px;
  color: #fff;
}
.products h1 b {
  color: #ff5f00;
}
.products h1::after {
  position: absolute;
  top: 100%;
  height: 4px;
  width: 100%;
  background-color: #ff5f00;
  display: table;
  content: " ";
}
.products .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.products .buttons button {
  color: #fff;
  background-color: #ff5f00;
  padding: 5px 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}
.products .buttons button:nth-child(2) {
  background-color: #202020;
  margin-left: 5px;
}
.products .vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 70vh;
}
.products .vertical div {
  width: 16.7%;
  overflow: hidden;
  height: 90%;
}
.products .vertical img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-shadow: none;
  transition: 0.3s;
  transform: scale(1);
}
.products .vertical img:hover {
  transition: 0.3s;
  transform: scale(1.1);
}
.products .productList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 25px;
}
.products .productList div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #222;
  font-weight: bold;
  font-size: 22px;
  color: #ddd;
  cursor: pointer;
  transition: 0.2s;
  transform: scale(1);
  text-transform: uppercase;
  box-shadow: none;
}
.products .productList div img {
  width: 325px;
  margin-bottom: 5px;
}
.products .productList div:hover {
  transition: 0.2s;
  transform: scale(1.05);
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.products .productList div b {
  margin: 10px 0;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
.products .productList div b span {
  color: #ff5f00;
  font-size: 14px;
}
.products .productList div b span.comingSoon {
  color: #fff;
  opacity: 0.4;
}

#buttonImg {
  width: 300px;
  transition: 0.3s;
  transform: scale(1);
  margin: 15px 0;
}
#buttonImg:hover {
  transition: 0.3s;
  transform: scale(1.05);
  cursor: pointer;
}/*# sourceMappingURL=products.css.map */