.faq {
  background-image: url("../../public/img/faq.png");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 15px 0;
}
.faq h2 {
  font-size: 3em;
  margin: 20px 0;
  margin-bottom: 5px;
}
.faq h3 {
  text-transform: uppercase;
  margin: 30px 0;
  margin-top: 5px;
}
.faq div.container {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5px;
}
.faq div.container div.question {
  margin: 5px 0;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 1100px;
  position: relative;
  overflow: hidden;
  height: 60px;
  animation: paddingAnimationReverse 0.6s both;
}
.faq div.container div.question b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  text-align: left;
  width: 100%;
  font-size: 1.5em;
  z-index: 999;
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  cursor: pointer;
  text-transform: capitalize;
}
.faq div.container div.question b svg {
  cursor: pointer;
}
.faq div.container div.question p {
  padding: 25px;
  padding-top: 0px;
  padding-left: 15px;
  text-align: left;
  font-weight: bold;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 1;
  animation: animationReverse 0.6s;
}
.faq div.container div.question b {
  margin-bottom: 15px;
  border-bottom: 2px solid rgba(255, 94, 0, 0.5568627451);
}
.faq div.container div.question b.no {
  border-bottom: none;
}
.faq div.container div.question.active {
  animation: paddingAnimation 0.6s both;
}
.faq div.container div.question.active b {
  margin-bottom: 15px;
  border-bottom: 2px solid rgba(255, 94, 0, 0.5568627451);
}
.faq div.container div.question.active p {
  display: block;
  animation: animation 0.6s;
}

@keyframes paddingAnimation {
  0% {
    padding-bottom: 0px;
    height: 60px;
  }
  100% {
    padding-bottom: 120px;
    height: 200px;
  }
}
@keyframes paddingAnimationReverse {
  0% {
    padding-bottom: 120px;
    height: 200px;
  }
  100% {
    padding-bottom: 0px;
    height: 60px;
  }
}
@keyframes animation {
  0% {
    top: -50px;
  }
  100% {
    top: 80px;
  }
}
@keyframes animationReverse {
  0% {
    top: 80px;
  }
  100% {
    top: -50px;
  }
}/*# sourceMappingURL=faq.css.map */