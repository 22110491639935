@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
nav .heading {
  position: relative;
  background-color: #1b1d25;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
}
nav .heading p {
  transition: 0.3s;
  padding: 20px;
}
nav .heading svg {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
  font-size: 1.5em;
  background-color: #111;
  padding: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
nav .heading svg path {
  transition: 0.3s;
}
nav .heading:hover {
  transition: 0.3s;
  background-color: #2d2f36;
}
nav .heading:hover p {
  transition: 0.3s;
  color: #ff5f00;
}
nav .heading:hover svg path {
  transition: 0.3s;
  color: #ff5f00;
}
nav h1 {
  padding: 15px 0;
  color: #ff5f00;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 2em;
  background-color: #0f1014;
  font-weight: 800;
}
nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f1014;
}
nav ul li {
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  padding: 25px 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  transform: scale(1);
  position: relative;
  z-index: 999;
}
nav ul li a {
  text-decoration: none;
}
nav ul li div {
  display: none;
  cursor: default;
  z-index: 999;
}
nav ul li:hover {
  transition: 0.3s;
  transform: scale(1.1);
}
nav ul li:nth-last-child(1):hover {
  transition: 0.2s;
  color: #c2410c;
  transform: scale(1);
  z-index: 999;
}
nav ul li:nth-last-child(1):hover div.container {
  position: absolute;
  top: calc(100% - 15px);
  left: 20px;
  display: block;
  padding-top: 15px;
  background-color: transparent;
  z-index: 999;
}
nav ul li:nth-last-child(1):hover div.container .list {
  padding: 10px 5px;
  background-color: #111;
  display: grid;
  grid-row-gap: 0px;
  z-index: 999;
}
nav ul li:nth-last-child(1):hover div.container .list span {
  margin: 4px 0;
  padding: 5px 10px;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0.6;
  cursor: pointer;
  transition: 0.2s;
}
nav ul li:nth-last-child(1):hover div.container .list span:hover {
  transition: 0.2s;
  opacity: 1;
  color: #c2410c;
}
nav ul li:nth-last-child(1):hover div.container .list span:hover a {
  transition: 0.2s;
  opacity: 1;
  color: #c2410c;
}/*# sourceMappingURL=navigation.css.map */